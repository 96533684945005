import type {
  QueryParams,
  Product,
  ProductAccessoryPrice,
  ProductCategory,
  ProductCategoryPagedResult,
  ProductDetailPagedResult,
  ProductLinePagedResult,
  ProductPagedResult,
  ProductPrice,
  ProductPropertyTypePagedResult,
  SlugTranslation,
  FileDetail,
  ProductLineDetail,
  ProductLinesNamesPagedResult,
  ProductLineProductImage,
  ProductWithProductLinePagedResult,
} from '~/types/api'
import { getBaseFilter } from '~/utils/APIFilters'

export function createProductsRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN = 'products'
  const DOMAIN_LINES = 'product-lines'
  const DOMAIN_PUBLIC_LINES = 'public-product-lines'
  const DOMAIN_CATEGORIES = 'product-categories'
  const DOMAIN_TRANSLATE = 'translate'
  const DOMAIN_PROPERTY_TYPES = 'product-property-types'
  const DOMAIN_PRODUCTS = 'products'
  const DOMAIN_DETAIL = 'details'
  const DOMAIN_NAMES = 'names'
  const DOMAIN_PRICES = 'prices'
  const DOMAIN_ACCESSORIES = 'product-accessories'
  const PARAMS_LANGUAGE = 'language'

  async function getAllLines(params?: QueryParams) {
    return get<ProductLinePagedResult>([DOMAIN_LINES], { params })
  }

  async function getAllLinesNames(params?: QueryParams) {
    if (!params) {
      params = {
        filter: APIFilters.makeFilter(getBaseFilter(true)),
        sort: APIFilters.makeSort({ title: 'ASC' }),
      }
    }

    return get<ProductLinesNamesPagedResult>([DOMAIN_LINES, DOMAIN_NAMES], {
      params,
    })
  }

  async function getLine(id: number) {
    return get<ProductLineDetail>([DOMAIN_LINES, id.toString()]) // TODO: langPath: [DOMAIN]
  }

  async function getLineBySlug(slug: string) {
    return get<ProductLineDetail>([DOMAIN_LINES, slug]) // TODO: langPath: [DOMAIN]
  }

  async function translateLineSlug(slug: string) {
    return get<SlugTranslation>([DOMAIN_LINES, slug, DOMAIN_TRANSLATE]) // TODO: langPath: [DOMAIN]
  }

  async function getAllProductLineImages(id: number) {
    return get<FileDetail[]>([DOMAIN_LINES, id.toString(), 'images']) // TODO: langPath: [DOMAIN]
  }

  async function getAllProductLineProductImages(productCategoryId?: number) {
    const params = {} as QueryParams
    if (productCategoryId !== undefined) {
      params.productCategoryId = productCategoryId
    }

    return get<ProductLineProductImage>([DOMAIN_LINES, 'product-images'], {
      params,
    }) // TODO: langPath: [DOMAIN]
  }

  async function getAllProducts(params?: QueryParams) {
    return get<ProductPagedResult>([DOMAIN_PRODUCTS], { params })
  }

  async function getAllProductsDetail(params?: QueryParams) {
    return get<ProductDetailPagedResult>([DOMAIN_PRODUCTS, DOMAIN_DETAIL], {
      params,
    })
  }

  async function getProduct(id: number) {
    return get<Product>([DOMAIN_PRODUCTS, id.toString()])
  }

  async function getAllCategories(params?: QueryParams) {
    if (!params) {
      params = {
        filter: APIFilters.makeFilter(getBaseFilter(true)),
        sort: APIFilters.makeSort({ weight: 'ASC', nid: 'ASC' }),
      }
    }

    return get<ProductCategoryPagedResult>([DOMAIN_CATEGORIES], {
      params,
    })
  }

  async function getCategory(id: number) {
    return get<ProductCategory>([DOMAIN_CATEGORIES, id.toString()], undefined)
  }

  async function getCategoryProductLines(id: number, params?: QueryParams) {
    return get<ProductLinePagedResult>(
      [DOMAIN_CATEGORIES, id.toString(), DOMAIN_PUBLIC_LINES],
      { params }
    )
  }

  async function getCategoryBySlug(slug: string) {
    return get<ProductCategory>([DOMAIN_CATEGORIES, slug])
  }

  async function translateCategorySlug(slug: string) {
    return get<SlugTranslation>([DOMAIN_CATEGORIES, slug, DOMAIN_TRANSLATE])
  }

  async function getAllPropertyTypes(params?: QueryParams) {
    return get<ProductPropertyTypePagedResult>([DOMAIN_PROPERTY_TYPES], {
      params,
    })
  }

  async function getAllProductPrices(
    productLineId: number,
    customerGroupId: number,
    priceCategoryId?: number
  ) {
    const params = {} as QueryParams
    if (priceCategoryId !== undefined) {
      params.priceCategoryId = priceCategoryId
    }

    return get<ProductPrice[]>(
      [
        DOMAIN_LINES,
        productLineId.toString(),
        DOMAIN_PRODUCTS,
        DOMAIN_PRICES,
        customerGroupId.toString(),
      ],
      {
        params,
      }
    ) // TODO: langPath: ['base', DOMAIN, DOMAIN_PRICES],
  }

  async function getAllAccessoryPrices(
    productLineId: number,
    customerGroupId: number,
    priceCategoryId?: number
  ) {
    const params = {} as QueryParams
    if (priceCategoryId !== undefined) {
      params.priceCategoryId = priceCategoryId
    }

    return get<ProductAccessoryPrice[]>(
      [
        DOMAIN_LINES,
        productLineId.toString(),
        DOMAIN_ACCESSORIES,
        DOMAIN_PRICES,
        customerGroupId.toString(),
      ],
      { params }
    ) // TODO: langPath: ['base', DOMAIN, DOMAIN_PRICES]
  }

  async function getAllFiltered(params: {
    productCategoryId?: number
    filterTagIds?: number[]
    productProperties?: string[]
  }) {
    return get<ProductWithProductLinePagedResult>([DOMAIN_PRODUCTS, 'filter'], {
      params,
    })
  }

  async function getAllFilteredPagination(params: {
    page: number
    itemsPerPage: number
    productCategoryId?: number
    filterTagIds?: number[]
    productProperties?: string[]
  }) {
    return get<ProductWithProductLinePagedResult>([DOMAIN_PRODUCTS, 'filter'], {
      params,
    })
  }

  return {
    getAllLines,
    getAllLinesNames,
    getLine,
    getLineBySlug,
    getAllProductLineImages,
    getAllProductLineProductImages,

    getAllProducts,
    getAllProductsDetail,
    getProduct,

    getAllCategories,
    getCategory,
    getCategoryProductLines,
    getCategoryBySlug,

    translateCategorySlug,
    translateLineSlug,

    getAllPropertyTypes,

    getAllProductPrices,
    getAllAccessoryPrices,

    getAllFiltered,
    getAllFilteredPagination,
  }
}
